<template>
  <div
    class="svg-item"
    :style="`width:${width};`"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      class="donut"
    >
      <circle
        class="donut-hole"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="#fff"
      />
      <circle
        class="donut-ring"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3.5"
      />
      <circle
        class="donut-segment"
        :style="`stroke: ${colorTypes[variant]};`"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3.5"
        :stroke-dasharray="`${percentage} ${100- toInt(percentage)}`"
        stroke-dashoffset="25"
      />
      <g
        class="donut-text donut-text-1"
        :style="`stroke:${colorTypes[variant]};`"
      >
        <text
          y="50%"
          transform="translate(0, 2)"
        >
          <tspan
            x="50%"
            text-anchor="middle"
            class="donut-percent"
            :style="`fill:${colorTypes[variant]}; stroke-width: 0;`"
          >{{ percentage }}%</tspan>
        </text>

      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: [String, Number],
      default: 0,
    },
    width: {
      type: [String, Number],
      default: '70%',
    },
    variant: {
      type: [String],
      default: 'primary',
    },
  },

  data() {
    return {
      colorTypes: {
        primary: '#3D61AD',
        red: '#ed1e79',
        yellow: '#d9e021',
        orange: '#FF6200',
      },
    }
  },
  methods: {
    toInt(value) {
      return parseInt(value, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
 .svg-item {
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
    animation: donutfade 1s;
}

@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
    0% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .svg-item {
        width: 80%;
    }
}

.donut-ring {
    stroke: #EBEBEB;
}

.donut-segment {
    transform-origin: center;

}

.donut-segment {

}

.donut-percent {
    animation: donutfadelong 1s;
}

@keyframes donutfadelong {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.donut-label {
    font-size: 0.28em;
    font-weight: 400;
    line-height: 1;
    fill: #000;
    transform: translateY(0.25em);
}

.donut-text {
    stroke-width: 0.5;
  }

.donut-percent {
    font-size: 7px;
    font-family: Comic Sans Ms;
    line-height: 1;
    font-weight: 100;
}

.donut-data {
    font-size: 0.12em;
    line-height: 1;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    color:#666;
    fill: #666;
    animation: donutfadelong 1s;
}

.svg-item {
  display:inline-block;
}
</style>
