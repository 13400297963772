<template>
  <b-modal
    id="add-update-whoweare-modal"
    size="lg"
    :ok-title="`${editing ? 'Update': 'Add'} Who We Are`"
    cancel-variant="outline-danger"
    @ok="addFaq"
    @hide="()=>{$emit('close');form ={}}"
    @close="()=>{$emit('close'); form={}}"
  >

    <b-row>
      <b-col cols="12">
        <validation-observer ref="whoweareForm">
          <validation-provider
            #default="{errors}"
            name="Title"
            rules="required"
          >
            <b-form-group label="Title">
              <b-form-input
                v-model="form.title"
                type="text"
                class="col-12"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    editing: {
      type: [Boolean, String],
      default: false,
    },
    whoweare: {
      type: [Array, Object, String],
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        title: '',
      },
    }
  },

  watch: {
    whoweare(value) {
      this.form = value
    },
  },

  methods: {
    addFaq() {
      const projectUid = this.$store.state.project.selectedProject
      if (this.editing) {
        const formattedData = {
          id: this.form.id,
          title: this.form.title,
          projectUid,
        }

        return useApollo.cms.updateWhoWeAre(formattedData).then(() => {
          this.showSuccess('Who We Are updated successfully')
          this.$emit('success')
        }).finally(() => {
          this.$bvModal.hide('add-update-whoweare-modal')
        })
      }

      return useApollo.cms.createWhoWeAre({ title: this.form.title, projectUid }).then(() => {
        this.showSuccess('Who We Are added successfully')
        this.$emit('success')
      }).finally(() => {
        this.$bvModal.hide('add-update-whoweare-modal')
      })
    },
  },
}
</script>
