<template>
  <div>
    <div v-if="canUpdate">
      <b-button
        variant="primary"
        class="ml-3 mt-2"
        @click="addWhoWeAre"
      >
        <feather-icon icon="PlusIcon" /> New
      </b-button>
    </div>
    <ul>
      <li
        v-for="item in whoWeAre"
        :key="item.id"
      >
        <div class="list-icon">
          <img
            :src="require(`@/assets/images/icons/${getRandomImage()}`)"
            alt="/"
          >
        </div>
        <p>
          {{ item.title }}
        </p>
        <div
          v-if="canUpdate"
          class="pl-3"
        >
          <span
            class="cursor-pointer"
            @click="editWhoWeAre(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="text-primary"
            />
          </span>
          <span class="cursor-pointer">
            <feather-icon
              icon="TrashIcon"
              class="text-danger ml-1"
              @click="deleteWhoWeAre(item)"
            />
          </span>
        </div>
      </li>
    </ul>
    <add-update-who-we-are
      v-if="canUpdate"
      :editing="editing"
      :whoweare="editingWhoweare"
      @close="()=>{editing=false; editingWhoweare={}}"
      @success="fetchWhoWeAre"
    />
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BButton } from 'bootstrap-vue'
import AddUpdateWhoWeAre from './AddUpdateWhoWeAre.vue'

export default {
  components: {
    AddUpdateWhoWeAre,
    BButton,
  },

  props: {
    canUpdate: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      whoWeAre: [],
      editingWhoweare: {},
      editing: false,
      images: [
        'b1.png',
        'b2.png',
        'b3.png',
      ],
    }
  },
  created() {
    this.fetchWhoWeAre()
  },
  methods: {
    addWhoWeAre() {
      this.$bvModal.show('add-update-whoweare-modal')
    },
    fetchWhoWeAre() {
      useApollo.cms.getWhoWeAre().then(res => {
        this.whoWeAre = res.data.whoWeAre.data
      })
    },

    editWhoWeAre(item) {
      this.editingWhoweare = item
      this.editing = true
      this.$bvModal.show('add-update-whoweare-modal')
    },

    deleteWhoWeAre(item) {
      const projectUid = this.$store.state.project.selectedProject
      useApollo.cms.deleteWhoWeAre({ id: parseInt(item.id, 10), projectUid }).then(() => {
        this.showSuccess('Who We Are deleted successfully')
        this.fetchWhoWeAre()
      })
    },

    getRandomImage() {
      return this.images[Math.floor(Math.random() * this.images.length)]
    },
  },
}
</script>
