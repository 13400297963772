<template>
  <div
    class="screenshot-section"
    data-aos="zoom-in"
    data-aos-duration="1500"
  >
    <div
      class="container"
    >
      <div class="text-right">
        <b-button
          v-if="canUpdate"
          variant="success"
          @click="addNewTab"
        >
          <feather-icon icon="PlusIcon" />
          Add New Tab
        </b-button>
      </div>
      <b-tabs class="screenshot-tabs">
        <b-tab
          v-for="tab in allTabs"
          :key="tab.id"
        >
          <template #title>
            <img
              :src="getStorage(tab.icon)"
              style="width:20px; height:20px; margin-right:5px;"
            >
            {{ tab.title }}
            <feather-icon
              v-if="canUpdate"
              icon="EditIcon"
              class="text-primary ml-1"
              size="25"
              @click="editTab(tab)"
            />

            <feather-icon
              v-if="canUpdate"
              icon="XIcon"
              class="text-danger ml-1"
              size="25"
              @click="deleteTab(tab)"
            />

          </template>
          <b-row class="py-2">
            <b-col cols="12 text-center">
              <h1 style="font-weight:600;">
                {{ tab.heading }}
              </h1>
              <p
                class="pt-1"
                style="font-size:20px;"
              >
                {{ tab.subtitle }}
              </p>
            </b-col>
            <b-col class="text-center">
              <img
                :src="getStorage(tab.image)"
                class="img-fluid pt-1"
              >
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <add-update-screenshot
        v-if="canUpdate"
        :screenshot="editingScreenshot"
        :editing="!!editingScreenshot.id"
        @close="closedAddEditScreenshotModal"
        @success="getScreenShotTabs()"
      />
    </div>
  </div>
</template>
<script>
import {
  BTabs, BTab, BButton, BRow, BCol,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import AddUpdateScreenshot from './AddUpdateScreenshot.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    AddUpdateScreenshot,
  },
  props: {
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allTabs: [],
      editingScreenshot: {},
    }
  },
  mounted() {
    this.getScreenShotTabs()
  },
  methods: {
    getScreenShotTabs() {
      useApollo.cms.getScreenshotTabs().then(res => {
        this.allTabs = res.data.screenshotTabs
      })
    },

    addNewTab() {
      this.$bvModal.show('add-update-screenshot-modal')
    },

    closedAddEditScreenshotModal() {
      this.editingScreenshot = {}
    },

    editTab(tab) {
      this.editingScreenshot = tab
      this.$bvModal.show('add-update-screenshot-modal')
    },

    deleteTab(tab) {
      const projectUid = this.$store.state.project.selectedProject
      const data = { id: tab.id, projectUid }
      useApollo.cms.deleteScreenshotTab(data).then(() => {
        this.showSuccess('Tab deleted Successfully')
        this.getScreenShotTabs()
      })
    },

  },
}
</script>

<style lang="scss">
.screenshot-section{
  background: #E3E7E0 !important;
  padding:10px;
  .screenshot-tabs{

.nav.nav-tabs{
  background:#E9EEF4 !important;
  border-radius:50px;
  margin:10px;
  width:max-content !important;
  min-height:50px;

  .nav-link{
    color:#3D61AD !important;
    font-weight: 600;
    height:100%;

    &:hover{
      border:none !important;
      background:none !important;
    }

    &::before{
      border:none !important;
      background: none !important;
    }

    &::after{
      border:none !important;
      background: none !important;
    }

  }

 .nav-link.active {
  background: #fff !important;
  border-radius:50px;
      &:active{
        border : none !important;
      }

      &::before{
      border:none !important;
      background: none !important;
    }

      &:after{
        background:none !important;
      }

  }

}
}
}

</style>
