<template>
  <b-modal
    id="add-update-screenshot-modal"
    size="lg"
    :ok-title="`${editing ? 'Update': 'Add'} Tab`"
    cancel-variant="outline-danger"
    @ok="addScreenshot"
    @hide="()=>{$emit('close'); image=null}"
    @close="()=>{$emit('close'); image=null}"
  >
    <b-row>
      <!-- <validation-observer ref="screenshotForm"> -->
      <b-col cols="12">
        <validation-provider
          #default="{errors}"
          name="Tab Title"
          rules="required"
        >
          <b-form-group label="Tab Title">
            <b-form-input
              v-model="form.title"
              type="text"
              placeholder="Tab Title"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{errors}"
          name="Tab Screenshot Header"
          rules="required"
        >
          <b-form-group label="Tab Screenshot Header">
            <b-form-input
              v-model="form.heading"
              type="text"
              placeholder="Tab Screenshot Header"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-form-group
          label="Tab Screenshot Image"
        >
          <custom-image-upload
            height="300"
            :url="image || getStorage(screenshot.image)"
            @imageUploaded="(img,url)=>{image = url }"
            @imageRemoved="()=>{
              form.image = null
            }"
          />
        </b-form-group>
        <b-form-group
          label="Tab Icon"
        >
          <custom-image-upload
            height="300"
            :url="logo || getStorage(screenshot.icon)"
            @imageUploaded="(img,url)=>{logo = url }"
            @imageRemoved="()=>{
              form.logo = null
            }"
          />
        </b-form-group>

        <validation-provider
          #default="{errors}"
          name="Screenshot Description"
          rules="required"
        >
          <b-form-group label="Screenshot Description">
            <textarea
              ref="myQuillEditor"
              v-model="form.subtitle"
              class="form-control w-100"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- </validation-observer> -->
    </b-row>
  </b-modal>
</template>
<script>

import {
  ValidationProvider,
} from 'vee-validate/dist/vee-validate.full'
import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import CustomImageUpload from '../CustomImageUpload.vue'

export default {
  components: {
    ValidationProvider,
    CustomImageUpload,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },

  props: {
    screenshot: {
      type: [Array, Object],
      default: () => {},
    },
    editing: {
      type: [String, Boolean],
      default: false,
    },
  },

  data() {
    return {
      form: {},
      editorOption: {
        placeholder: 'Please write some description about slider',
      },
      image: null,
      logo: null,
    }
  },

  watch: {
    screenshot(value) {
      this.form = value
    },
  },

  methods: {
    addScreenshot() {
      const projectUid = this.$store.state.project.selectedProject
      if (this.editing) {
        const { id, __typename, ...form } = this.form
        if (this.image) form.image = this.image
        if (this.logo) form.icon = this.logo

        return useApollo.cms.updateScreenshotTab({ input: form, id: this.screenshot.id, projectUid }).then(() => {
          this.showSuccess('Tab Updated Successfully')
        }).finally(() => {
          this.$emit('success')
          this.$bvModal.hide('add-update-screenshot-modal')
        })
      }

      this.form.image = this.image
      this.form.icon = this.logo

      return useApollo.cms.createScreenshotTab({ projectUid, input: this.form }).then(() => {
        this.showSuccess('Tab Created Successfully')
      }).finally(() => {
        this.$emit('success')
        this.$bvModal.hide('add-update-screenshot-modal')
      })
    },
  },
}
</script>
<style lang="scss">

#add-update-screeshot-modal {
  .modal-content {
    min-height: 400px;
  }
}
</style>
