<template>
  <section
    class="my-3"
    data-aos="zoom-in"
    data-aos-duration="1500"
  >
    <h1 class="service-title text-center pb-1">
      {{ reviewSection.title }}
    </h1>
    <input
      v-if="canUpdate"
      v-model="reviewSection.title"
      class="form-control w-75 mx-auto"
      @change=" updateSection()"
    >
    <p class="pb-3 text-center">
      {{ reviewSection.description }}
    </p>
    <div
      class="d-flex justify-content-center"
    >
      <textarea
        v-if="canUpdate"
        v-model="reviewSection.description"
        class="p-1 w-75"
        @change=" updateSection()"
      />
    </div>
    <div class="text-right container pb-2 pt-2">
      <b-button
        v-if="canUpdate"
        variant="primary"
        @click="$bvModal.show('add-update-review-modal')"
      >
        <feather-icon icon="PlusIcon" />
        Add New Testimonial
      </b-button>
    </div>
    <div
      v-if="canUpdate"
      class="edit-icons-review mr-5 pr-xl-5 pb-2"
    >
      <button @click="editTestimonial()">
        <feather-icon
          icon="EditIcon"
          class="text-workzone"
        />
      </button>
      <button
        class="ml-1"
        @click="deleteTestimonial()"
      >
        <feather-icon
          icon="TrashIcon"
          class="text-danger"
        />
      </button>
    </div>
    <swiper
      ref="swiper"
      v-model="isActive"
      :options="swiperOptions"
      @slide-change="slideChange"
    >
      <swiper-slide
        v-for="testimonial in testimonials"
        :key="testimonial.id"
      >

        <b-card class="container ">
          <b-row class="d-flex align-items-center review-row">
            <b-col lg="4">
              <img
                :src="getStorage(testimonial.image)"
                class="img-fluid"
                style="max-height:250px;border-radius: 15px;"
              >
            </b-col>
            <b-col
              lg="8"
            >
              <h6>
                {{ testimonial.name }} | {{ testimonial.position }}, {{ testimonial.company }}
              </h6>
              <h3 style="font-family: Macan,Helvetica Neue,Helvetica,Arial,sans-serif;">
                <blockquote>
                  <q v-html="testimonial.testimonial" />
                </blockquote>
              </h3>
            </b-col>
          </b-row>

        </b-card>
      </swiper-slide>

      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      />

    </swiper>
    <add-update-review
      v-if="canUpdate"
      :testimonial="editingTestimonial"
      :editing="editing"
      @close="()=>{editing=false;editingTestimonial={}}"
      @success="fetchTestimonials()"
    />
    <add-update-carousel />
  </section>
</template>
<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import useApollo from '@/plugins/graphql/useApollo'
import AddUpdateReview from './AddUpdateReview.vue'
import AddUpdateCarousel from './AddUpdateCarousel.vue'

export default {
  components: {
    BRow,
    BCol,
    Swiper,
    SwiperSlide,
    BCard,
    BButton,
    AddUpdateReview,
    AddUpdateCarousel,
  },
  props: {
    canUpdate: {
      type: [Boolean, String],
      default: false,
    },
    reviewSection: {
      type: [Array, Object],
      default: () => {},
    },
  },

  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 25,
        allowTouchMove: false,
        resistanceRatio: 0,
        initialSlide: 0,
        loop: true,
        autoPlay: false,
        // watchSlidesProgress: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        a11y: false,
        // noSwipingSelector: 'button',
        preventClicks: false,
        preventClicksPropagation: false,
      },
      testimonials: [],
      editing: false,
      isActive: 0,
      editingTestimonial: {},
    }
  },

  mounted() {
    this.fetchTestimonials()
  },

  methods: {
    fetchTestimonials() {
      useApollo.cms.getTestimonials().then(res => {
        this.testimonials = res.data.testimonial.data
        this.isActive = this.testimonials.length - 1
      })
    },

    slideChange() {
      this.isActive = this.$refs.swiper.$swiper.realIndex
    },

    editTestimonial() {
      this.editingTestimonial = this.testimonials[this.isActive]
      this.editing = true
      this.$bvModal.show('add-update-review-modal')
    },

    updateSection() {
      this.$forceUpdate()
      const projectUid = this.$store.state.project.selectedProject
      const formData = {
        title: this.reviewSection.title,
        description: this.reviewSection.description,
        section: 'testimonials',
      }

      useApollo.cms.updateSection({ id: this.reviewSection.id, projectUid, input: formData }).then(() => {
        this.$emit('sectionUpdated')
      })
    },

    deleteTestimonial() {
      const projectUid = this.$store.state.project.selectedProject
      const testimonial = this.testimonials[this.isActive]
      useApollo.cms.deleteTestimonial({ id: testimonial.id, projectUid }).then(() => {
        this.showSuccess('Testimonial Deleted Successfully')
        this.fetchTestimonials()
      })
    },
  },
}
</script>
<style lang="scss">

.swiper-button-next,
.swiper-button-prev {
  background-color: #161E31;
  top: 30%;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

@media screen and (min-width: 1400px) {
  .swiper-button-prev {
  left: 120px !important;
  top: 46%;
}
.swiper-button-next {
  right: 120px !important;
  top: 46%;
}
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #f1f1f1;
  font-size: 20px !important;
  font-weight: 700;
}
.dark-layout {
  .swiper-button-next,
  .swiper-button-prev {
    background-color: #fff;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: #333;
  }
}

.edit-icons-review{
  text-align:right;
  button{
    background-color: #dcdcff;
    border:none;
    border-radius: 100px;
    height:30px;
    width:30px;
    font-weight: 800;
    cursor:pointer;
    z-index:1000 !important;
  }
}

.swiper-slide{
  z-index:1;
}

.swiper-wrapper{
  z-index:-1 !important;
}

.review-row{
  min-height: 290px;
}
</style>
