<template>
  <b-modal
    id="add-update-result-modal"
    size="lg"
    :ok-title="`${editing ? 'Update': 'Add'} Result`"
    cancel-variant="outline-danger"
    @ok="addResult"
    @hide="()=>{$emit('close');form ={}}"
    @close="()=>{$emit('close'); form={}}"
  >

    <b-row>
      <b-col cols="12">
        <validation-observer ref="resultForm">
          <validation-provider
            #default="{errors}"
            name="Result Title"
            rules="required"
          >
            <b-form-group label="Result Title">
              <b-form-input
                v-model="form.title"
                type="text"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{errors}"
            name="Result Percentage"
            rules="required|max_value:100"
          >
            <b-form-group label="Result Percentage">
              <b-form-input
                v-model="form.percentage"
                type="number"
                placeholder="Percentage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    editing: {
      type: [Boolean, String],
      default: false,
    },
    result: {
      type: [Array, Object],
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        title: '',
        percentage: '',
      },
    }
  },

  watch: {
    result(value) {
      this.form = value
    },
  },

  methods: {
    addResult() {
      const projectUid = this.$store.state.project.selectedProject
      if (this.editing) {
        const formattedData = {
          id: this.form.id,
          title: this.form.title,
          percentage: parseInt(this.form.percentage, 10),
          projectUid,
        }

        return useApollo.cms.updateResult(formattedData).then(() => {
          this.showSuccess('Result updated successfully')
          this.$emit('success')
        }).finally(() => {
          this.$bvModal.hide('add-update-result-modal')
        })
      }

      return useApollo.cms.addResult({ ...this.form, percentage: parseInt(this.form.percentage, 10), projectUid }).then(() => {
        this.showSuccess('Result added successfully')
        this.$emit('success')
      }).finally(() => {
        this.$bvModal.hide('add-update-result-modal')
      })
    },
  },
}
</script>
