var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-update-review-modal","size":"lg","ok-title":((_vm.editing ? 'Update': 'Add') + " Testimonial"),"cancel-variant":"outline-danger"},on:{"ok":_vm.addFaq,"hide":function (){_vm.$emit('close');_vm.image =null; _vm.form={}},"close":function (){_vm.$emit('close'); _vm.image=null; _vm.form={}}}},[_c('b-row',[_c('validation-observer',{ref:"faqForm"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Reviewer Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reviewer Name"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Reviewer Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Name"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Company Name"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"User Role"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Role"},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}})],1),_c('b-form-group',{attrs:{"label":"User Image"}},[_c('custom-image-upload',{attrs:{"height":"300","url":_vm.image || _vm.getStorage(_vm.testimonial.image)},on:{"imageUploaded":function (img,url){_vm.image = url },"imageRemoved":function (){
              _vm.form.image = null
            }}})],1),_c('validation-provider',{attrs:{"name":"Review","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Review"}},[_c('quill-editor',{ref:"myQuillEditor",model:{value:(_vm.form.testimonial),callback:function ($$v) {_vm.$set(_vm.form, "testimonial", $$v)},expression:"form.testimonial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }