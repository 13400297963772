var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-update-screenshot-modal","size":"lg","ok-title":((_vm.editing ? 'Update': 'Add') + " Tab"),"cancel-variant":"outline-danger"},on:{"ok":_vm.addScreenshot,"hide":function (){_vm.$emit('close'); _vm.image=null},"close":function (){_vm.$emit('close'); _vm.image=null}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Tab Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tab Title"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tab Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Tab Screenshot Header","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tab Screenshot Header"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tab Screenshot Header"},model:{value:(_vm.form.heading),callback:function ($$v) {_vm.$set(_vm.form, "heading", $$v)},expression:"form.heading"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tab Screenshot Image"}},[_c('custom-image-upload',{attrs:{"height":"300","url":_vm.image || _vm.getStorage(_vm.screenshot.image)},on:{"imageUploaded":function (img,url){_vm.image = url },"imageRemoved":function (){
            _vm.form.image = null
          }}})],1),_c('b-form-group',{attrs:{"label":"Tab Icon"}},[_c('custom-image-upload',{attrs:{"height":"300","url":_vm.logo || _vm.getStorage(_vm.screenshot.icon)},on:{"imageUploaded":function (img,url){_vm.logo = url },"imageRemoved":function (){
            _vm.form.logo = null
          }}})],1),_c('validation-provider',{attrs:{"name":"Screenshot Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Screenshot Description"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.subtitle),expression:"form.subtitle"}],ref:"myQuillEditor",staticClass:"form-control w-100",domProps:{"value":(_vm.form.subtitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "subtitle", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }