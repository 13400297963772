<template>
  <section
    class="big-idea-section my-3"
    data-aos="fade-in"
    data-aos-duration="1500"
  >
    <div
      v-if="canUpdate"
      class="text-right p-2"
    >
      <b-button
        variant="primary"
        @click="addNewSlider"
      >
        <feather-icon icon="PlusIcon" />
        Add New Slider
      </b-button>
    </div>
    <div
      v-if="canUpdate"
      class="edit-icons-review pr-5 mr-5"
    >
      <button @click="editSlider()">
        <feather-icon
          icon="EditIcon"
          class="text-workzone"
        />
      </button>
      <button
        class="ml-1"
        @click="deleteSlider()"
      >
        <feather-icon
          icon="TrashIcon"
          class="text-danger"
        />
      </button>
    </div>
    <swiper
      ref="carouselSlider"
      :options="swiperOptions"
      @slide-change="slideChange"
    >
      <swiper-slide
        v-for="slider in sliders"
        :key="slider.id"
      >
        <div class="container pb-1 pt-3">

          <div class="row pb-2">
            <div class="col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <h1 class="big-title">
                {{ slider.title }}
              </h1>
              <p
                class="pt-2"
                v-html="slider.description"
              />
            </div>
            <div class="col-lg-6 text-right">
              <img
                :src="getStorage(slider.image)"
                class="img-fluid bulb-image"
              >

            </div>
          </div>
        </div>
      </swiper-slide>

      <!-- <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      /> -->

      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <add-update-carousel
      v-if="canUpdate"
      :slider="editingSlider"
      :editing="!!editingSlider.id"
      @close="closedAddEditSliderModal"
      @success="getSliders()"
    />
  </section>
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import useApollo from '@/plugins/graphql/useApollo'
import { BButton } from 'bootstrap-vue'
import AddUpdateCarousel from './AddUpdateCarousel.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BButton,
    AddUpdateCarousel,
  },
  directives: {
    swiper: directive,
  },
  props: {
    canUpdate: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        autoplay: { delay: 8000, disableOnInteraction: false },
        loop: true,
        preventClicks: false,
        preventClicksPropagation: false,
        a11y: false,
        spaceBetween: 25,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      },
      sliders: [],
      editingSlider: {},
      isActive: 0,

    }
  },

  mounted() {
    this.getSliders()
  },

  methods: {
    getSliders() {
      useApollo.cms.getSlider().then(res => {
        this.sliders = res.data.slider.data
        this.isActive = this.sliders.length - 1
      })
    },

    slideChange() {
      this.isActive = this.$refs.carouselSlider.$swiper.realIndex
    },

    addNewSlider() {
      this.$bvModal.show('add-update-slider-modal')
    },

    closedAddEditSliderModal() {
      this.editingSlider = {}
    },

    editSlider() {
      const slider = this.sliders[this.isActive]
      this.editingSlider = slider
      this.$bvModal.show('add-update-slider-modal')
    },

    deleteSlider() {
      const projectUid = this.$store.state.project.selectedProject
      const slider = this.sliders[this.isActive]
      useApollo.cms.deleteSlider({ id: slider.id, projectUid }).then(() => {
        this.showSuccess('Slider Deleted successfully')
      }).finally(() => {
        this.getSliders()
      })
    },

  },
}
</script>
<style  lang="scss">
@import '@core/scss/base/pages/splash-screen.scss';
.big-idea-section{
    @media screen and (min-width: 1400px) {
    .swiper-button-prev {
    left: 20px !important;
    top: 46%;
  }
  .swiper-button-next {
    right: 20px !important;
    top: 46%;
  }
  .bulb-image{
  max-height: 400px;
  }
  }
  .swiper-pagination-bullet{
    width:12px;
    background: white;
    height:12px;
  }
}

</style>
