<template>
  <div class="row pt-3 result-row">
    <div
      v-if="canUpdate"
      class="text-right col-12 p-2"
    >
      <b-button
        variant="primary"
        @click="$bvModal.show('add-update-result-modal')"
      >
        <feather-icon icon="PlusIcon" />
        Add New Result
      </b-button>
    </div>
    <div
      v-for="result,i in results"
      :key="i"
      class="col-lg-3 col-md-4 col-sm-6"
    >
      <div class="card p-2 result-card position-relative">
        <div
          v-if="canUpdate"
          class="position-absolute result-action d-none"
          style="top:5px;right:10px;"
        >
          <feather-icon
            icon="EditIcon"
            size="17"
            class="text-primary"
            @click="editResult(result)"
          />
          <feather-icon
            icon="TrashIcon"
            size="17"
            class="text-danger ml-1"
            @click="deleteResult(result)"
          />
        </div>
        <dounut
          :percentage="result.percentage"
          :variant="getVariant(result.percentage)"
        />
        <h6 class="text-center mt-1">
          {{ result.title }}
        </h6>
      </div>
    </div>
    <add-update-result
      v-if="canUpdate"
      :result="editingResult"
      :editing="!!editingResult.id"
      @close="()=>{editing=false; editingResult={}}"
      @success="getResults()"
    />
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BButton } from 'bootstrap-vue'
import Dounut from '../Dounut.vue'
import AddUpdateResult from './AddUpdateResult.vue'

export default {
  components: {
    Dounut,
    BButton,
    AddUpdateResult,
  },
  props: {
    canUpdate: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      results: [],
      editingResult: {},
      editing: false,
    }
  },

  mounted() {
    this.getResults()
  },
  methods: {
    getResults() {
      useApollo.cms.getResults().then(res => {
        this.results = res.data.result.data
      })
    },

    editResult(result) {
      this.editingResult = result
      this.editing = true
      this.$bvModal.show('add-update-result-modal')
    },

    deleteResult(result) {
      const projectUid = this.$store.state.project.selectedProject
      useApollo.cms.deleteResult({ id: parseInt(result.id, 10), projectUid }).then(() => {
        this.showSuccess('Result deleted successfully')
        this.getResults()
      })
    },

    getVariant(percentage) {
      if (percentage > 30 && percentage <= 70) {
        return 'yellow'
      }
      if (percentage > 70) {
        return 'primary'
      }
      return 'red'
    },
  },
}
</script>

<style lang="scss">
.result-card{
  cursor: pointer;
  &:hover{
    .result-action{
    display: block !important;
  }

  }

}
</style>
