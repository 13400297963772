<template>
  <b-modal
    id="add-update-review-modal"
    size="lg"
    :ok-title="`${editing ? 'Update': 'Add'} Testimonial`"
    cancel-variant="outline-danger"
    @ok="addFaq"
    @hide="()=>{$emit('close');image =null; form={}}"
    @close="()=>{$emit('close'); image=null; form={}}"
  >

    <b-row>
      <validation-observer ref="faqForm">
        <b-col cols="12">
          <validation-provider
            #default="{errors}"
            name="Reviewer Name"
            rules="required"
          >
            <b-form-group label="Reviewer Name">
              <b-form-input
                v-model="form.name"
                type="text"
                placeholder="Reviewer Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{errors}"
            name="Company Name"
            rules="required"
          >
            <b-form-group label="Company Name">
              <b-form-input
                v-model="form.company"
                type="text"
                placeholder="Company Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-group label="User Role">
            <b-form-input
              v-model="form.position"
              type="text"
              placeholder="Role"
            />

          </b-form-group>

          <b-form-group label="User Image">
            <custom-image-upload
              height="300"
              :url="image || getStorage(testimonial.image)"
              @imageUploaded="(img,url)=>{image = url }"
              @imageRemoved="()=>{
                form.image = null
              }"
            />
          </b-form-group>

          <validation-provider
            #default="{errors}"
            name="Review"
            rules="required"
          >
            <b-form-group label="Review">
              <quill-editor
                ref="myQuillEditor"
                v-model="form.testimonial"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </validation-observer>
    </b-row>
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useApollo from '@/plugins/graphql/useApollo'
import CustomImageUpload from '../CustomImageUpload.vue'

export default {
  components: {
    quillEditor,
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    CustomImageUpload,
  },

  props: {
    editing: {
      type: [Boolean, String],
      default: false,
    },
    testimonial: {
      type: [Array, Object],
      default: () => {},
    },
  },

  data() {
    return {
      form: {},
      image: null,
    }
  },

  watch: {
    testimonial(value) {
      this.form = value
    },
  },

  methods: {
    addFaq() {
      const projectUid = this.$store.state.project.selectedProject
      if (this.editing) {
        const formattedData = {
          id: this.form.id,
          input: {
            position: this.form.position,
            company: this.form.company,
            name: this.form.name,
            testimonial: this.form.testimonial,
            image: this.image || this.form.image,
          },
          projectUid,
        }

        return useApollo.cms.updateTestimonial(formattedData).then(() => {
          this.showSuccess('Testimonial updated successfully')
          this.$emit('success')
        }).finally(() => {
          this.$bvModal.hide('add-update-review-modal')
        })
      }

      const formattedData = {
        ...this.form,
        image: this.image,
      }
      return useApollo.cms.createTestimonial({ input: formattedData, projectUid }).then(() => {
        this.showSuccess('Testimonial added successfully')
        this.$emit('success')
      }).finally(() => {
        this.$bvModal.hide('add-update-review-modal')
      })
    },
  },
}
</script>

  <style lang="scss">
    #add-update-review-modal {
    .modal-content {
    min-height: 400px;
    }
    .ql-editor {
    min-height: 200px;
    }
    }
  </style>
